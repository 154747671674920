
import React from 'react';
import './Certificate.css';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { images } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Certificate = () => {
  return (
    <div id="portfolio">
        <div class="container">
            <h1 class="head-text" style={{textAlign:'center',color:'white'}}>My Certifications</h1>
            <div class="work-list">
                <div class="work">
                    <img src={images.meta}/>
                    <div class="layer">
                    <h3>FrontEnd Ceritificate</h3>
                    <p>I've earned a Frontend Development certificate from Meta, boosting my web development expertise.</p>
                    <a href='https://www.coursera.org/' target='_blank'> <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
                    </div>
                    </div>
                    <div class="work">
                    <img src={images.ibm}/>
                    <div class="layer">
                    <h3>Cyber Security </h3>
                    <p>I've obtained a Cybersecurity certificate from IBM, advancing my security knowledge.</p>
                    <a href='#'> <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
                    </div>
                    </div>
                    <div class="work">
                    <img src={images.duke}/>
                    <div class="layer">
                    <h3>Programming</h3>
                    <p>I've secured a Programming certificate from Duke, enhancing my coding skills.</p>
                    <a href='#'> <FontAwesomeIcon icon={faUpRightFromSquare} /></a>
                    </div>
                    </div>
            </div>
            <a href="#" class="btn">See more</a>
        </div>
    </div>
  );
};

export default Certificate;
