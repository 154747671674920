import image from '../assets/Jain_Logo.png'

export const education = [

    {
      id: 0,
      img: {image},
      school: "Jain (Deemed-to-be University), Bangalore",
      date: "Oct 2023 - Sep 2025",
      grade: "8.71 CGPA",
      desc: "I am currently pursuing a Master's degree in Computer Applications at Jain University, Bangalore. I have successfully completed my first year, maintaining a CGPA of 8.71. My academic journey has been enriched with comprehensive courses in Advanced Data Structures, Modern Algorithms, Object-Oriented Programming, Advanced Database Management Systems, Operating Systems, and Computer Networks, among other key areas.",
      degree: "MCA - Computer Science and Information Technology",
    },
    {
      id: 1,
      img: {image},
      school: "Jain (Deemed-to-be University), Bangalore",
      date: "Apr 2019 - Apr 2022",
      grade: "80.1%",
      desc: "I have completed my Bachelor of Computer Applications degree from Jain University, Bangalore, with an impressive overall percentage of 80.1%. During my studies, I delved into a wide array of subjects such as Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others.",
      degree: "BCA, Science with Computer",
    },
    
  ];