import React, { useState, useEffect } from "react";
import "./SnackBar.scss"; // You can define your styles in this CSS file

const Snackbar = ({ message }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setIsVisible(true);

      // Hide the snackbar after 3 seconds (3000 milliseconds)
      const timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3000000);

      // Clear the timeout if the component unmounts before the timeout completes
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [message]);

  return <div className={`snackbar ${isVisible ? "show" : ""}`}>{message}</div>;
};

export default Snackbar;
