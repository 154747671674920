import React from 'react'
import './ProgressBar.scss';
import {motion,AnimatePresence} from 'framer-motion'
import { useState } from 'react';

const ProgressBar = () => {
    const [isVisible, setIsVisible] = useState(true);

  const handleAnimationComplete = () => {
    setIsVisible(false);
  };
  return (
    <div>
        <div className='first_div_element'>
        <AnimatePresence>
        {isVisible && (
          <motion.div
            className='second_div_element'
            initial={{ width: '0%' }}
            animate={{ width: '100%' }}
            exit={{ width: '110%' }}
            transition={{ duration: 3, ease: 'easeInOut' }}
            onAnimationComplete={handleAnimationComplete}
          >
          </motion.div>
        )}
      </AnimatePresence>
        </div>
    </div>
  )
}

export default ProgressBar