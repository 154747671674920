import React from 'react'
import { About,Footer,Header,Skills,Testimonials,Work,Resume } from './container';
import { ChakraProvider } from '@chakra-ui/react';
import { Navbar } from './components';
import './App.scss'
import ProgressBar from './container/ProgressBar/ProgressBar';
import CustomCursor from './components/CustomCursor/CustomCursor ';
import Tail from './container/Tail/Tail'
import SkillsCircle  from './container/skillscircle/skillscircle';
import BirdsEffect from './container/BirdsEfect/BirdsEffect';
import ContactForm from './container/contactForm/ContactForm';
import Earth from './container/Contact/canvas/Earth';
import Contact from './container/Contact/Contact';
import Tailwind from './Tailwind'
import Certificate from './container/Certificates/Certificate';
import footerCard from './container/Footer/FooterCard';
import FooterSection from './FooterSection/FooterSection';
import Education from './Education/Education'
import BannerAd from './BannerAd';

const App = () => {
  return (
    <>
    <div className='app'>
        <ProgressBar />
        <CustomCursor />
        <Navbar/>
        {/* <Tailwind /> */}
        <Header />
        {/* <BannerAd /> */}
        <About />
        <Work />
        <Skills />
        <BirdsEffect />
        <Certificate />
        {/* <ContactForm/>
        <Tail />
        <Footer /> */}
        <footerCard />
        <Education/>
        <Contact />
        {/* <Testimonials /> */}
        <FooterSection/>
    </div>
    </>
  )
}

export default App