import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './CustomCursor.css'
const CustomCursor = () => {
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;

    document.addEventListener('mousemove', (e) => {
      gsap.to(cursor, {
        x: e.clientX - cursor.offsetWidth / 2,
        y: e.clientY - cursor.offsetHeight / 2,
        duration: 0.0,
      });
    });

    return () => {
      document.removeEventListener('mousemove', () => {});
    };
  }, []);

  return <div className="custom-cursor" ref={cursorRef}></div>;
};

export default CustomCursor;
