import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';


const actions = [
    {
      name: 'Print',
      icon: <PrintIcon />, // You need to import PrintIcon from @mui/icons-material/Print
      onClick: () => {
        // Add logic to handle printing here
        window.print();
      },
    },
    {
      name: 'Share',
      icon: <ShareIcon />, // You need to import ShareIcon from @mui/icons-material/Share
      onClick: () => {
        // Add logic to handle sharing here
        // For example, you can use the Web Share API to implement sharing functionality
        if (navigator.share) {
          navigator.share({
            title: document.title,
            text: 'Check out this page!',
            url: window.location.href,
          })
          .then(() => console.log('Shared successfully'))
          .catch((error) => console.error('Error sharing:', error));
        } else {
          // Fallback logic for browsers that do not support Web Share API
          console.log('Web Share API not supported');
        }
      },
    },
  ];
  
  
const SpeedDialfun = () => {
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ position: 'absolute', bottom: 16, right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialfun;
