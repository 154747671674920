// import styled from 'styled-components';
import './Resume.scss';
import { BsArrowDownCircle } from 'react-icons/bs';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Resume = () => {
  const handleDownload = () => {
    toast.success('Resume downloaded successfully!');
  };

  // const ResumeButton = styled.a`
  //   -webkit-appearance: button;
  //   -moz-appearance: button;
  //   appearance: button;
  //   text-decoration: none;

  //   width: 95%;
  //   max-width: 300px;
  //   text-align: center;
  //   padding: 16px 0;

  //   background: hsla(271, 100%, 50%, 1);
  //   background: linear-gradient(
  //     225deg,
  //     hsla(271, 100%, 50%, 1) 0%,
  //     hsla(294, 100%, 50%, 1) 100%
  //   );
  //   background: -moz-linear-gradient(
  //     225deg,
  //     hsla(271, 100%, 50%, 1) 0%,
  //     hsla(294, 100%, 50%, 1) 100%
  //   );
  //   background: -webkit-linear-gradient(
  //     225deg,
  //     hsla(271, 100%, 50%, 1) 0%,
  //     hsla(294, 100%, 50%, 1) 100%
  //   );
  //   box-shadow: 20px 20px 60px #1f2634, -20px -20px 60px #1f2634;
  //   border-radius: 50px;
  //   font-weight: 600;
  //   font-size: 20px;

  //   &:hover {
  //     transform: scale(1.05);
  //     transition: all 0.4s ease-in-out;
  //     box-shadow: 20px 20px 60px #1F2634;
  //     filter: brightness(1);
  //   }

  //   @media (max-width: 640px) {
  //     padding: 12px 0;
  //     font-size: 18px;
  //   }
  //   color: white;
  // `;

  return (
    <>
      <ToastContainer />
      <div className="tag-cmp app__flex resume-res" id='change' style={{ display: 'flex' }}>
        <a
          href='Suriya_Developer_Resume.pdf'
          download='Suriya_Developer_Resume.pdf'
          onClick={handleDownload}
          style={{ textDecoration: 'none', color: 'white', display: 'flex', flex: '1' }}
          className='resume-res'
        >
          Download Resume <BsArrowDownCircle size={20} color="lightblue" style={{ paddingTop: '5px' }} />
        </a>
      </div>
    </>
  );
};

export default Resume;
