import React from 'react'
import { images } from '../../constants';
import styled, { keyframes } from 'styled-components';
import './SkillsCircle.css';

const rotateAnimation = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
`;

const ImagesCircle = styled.div`
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: ${rotateAnimation} 20s linear infinite;
`;


const SkillsCircle  = () => {
  return (
    <>
    <div>
         <div class="container-circle" style={{float: 'left',
            width: '200px',
            height: '200px',
            position: 'relative',
            perspective: '1000px',
            marginTop: '55%',
            marginLeft: '20%',
            transformStyle: 'preserve-3d',
            transform: 'rotateX(-20deg) rotateZ(-10deg)',
            }}>
       <ImagesCircle>
        <div class="images-circle" style={{
           width: '100%',
           position: 'absolute',
           transformStyle: 'preserve-3d',
           animation: 'rotate 20s linear infinite',
        }}>
            <img src={images.redux} style={{transform: 'rotateY(60deg) translateZ(200px)', width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css'className='hovereffect-vl'/>
            <img src={images.node} style={{transform: 'rotateY(120deg) translateZ(200px)',width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css' className='hovereffect'/>
            <img src={images.nextjs} style={{transform: 'rotateY(180deg) translateZ(200px)',width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css' className='hovereffect-black'/>
            <img src={images.mongodb} style={{transform: 'rotateY(240deg) translateZ(200px)',width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css' className='hovereffect'/>
            <img src={images.sass} style={{transform: 'rotateY(300deg) translateZ(200px)',width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css' className='hovereffect-pink'/>
            <img src={images.react} style={{transform: 'rotateY(360deg) translateZ(200px)',width: '100px',
                height: '100px',
                position: 'absolute',
                borderRadius: '50%'}} alt='css' className='hovereffect-bl'/>

        </div>
        </ImagesCircle>
        </div>
    </div>
    </>
  )
};

export default SkillsCircle